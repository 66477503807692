import { Box, IconButton, Spinner } from '@chakra-ui/react';
import {
  KustomPage,
  KustomPageComponent,
  RoomListComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import React, { useContext, useMemo } from 'react';

import { $kustomAdminBarHeightVarName } from '@/lib/kustomcms-sdk/lib/admin/components/KustomAuthModal';
import PagesContext from '@/lib/kustomcms-sdk/lib/contexts/pages';
import { useRouter } from 'next/router';
import Section from '../Section';
import { IconSvg } from '../IconSvg';
import { ScrollPan } from './ScrollPan';
import useTranslatedString from '@/lib/kustomcms-sdk/lib/hooks/useTranslatedString';

interface RoomLayoutProps {
  route: string;
  children: React.ReactNode;
}

const RoomLayout: React.FC<RoomLayoutProps> = ({ children, route }) => {
  const pagesContext = useContext(PagesContext);
  const router = useRouter();

  const roomsPage = pagesContext.pages.find((page) => page.model === 'rooms');

  const roomsCmps = roomsPage?.components?.filter(
    (cmp) => cmp.type === 'roomList',
  ) as KustomPageComponent<RoomListComponentData>[];

  const roomsListCmp = roomsCmps?.[0];
  // const suitesListCmp = roomsCmps?.[1];

  const list = useMemo(() => {
    const list: KustomPage[] = [];
    const roomsList = roomsListCmp?.data.rooms
      .map((roomId) => pagesContext.rooms.find((room) => room._id === roomId))
      .filter(
        (room) => !!room && roomsListCmp?.data.activeRoomsId.includes(room._id),
      ) as KustomPage[];
    if (roomsList && roomsList.length > 0) list.push(...roomsList);

    // const suitesList = suitesListCmp?.data.activeRoomsId
    //   .map((roomId) => pagesContext.rooms.find((room) => room._id === roomId))
    //   .filter((room) => !!room)
    //   .sort((a, b) => a!.index - b!.index) as KustomPage[];
    // if (suitesList && suitesList.length > 0) list.push(...suitesList);

    return list;
    // }, [roomsListCmp, suitesListCmp]);
  }, [roomsListCmp, pagesContext.rooms]);

  const roomsPageHref = useTranslatedString(
    roomsPage?.prettyUrl || { fr: '#' },
  );

  const goBack = () => {
    const bt = document.querySelector(
      '.diadao-rooms-sticky-opened .diadao-hsw-qs-roomstickydetail .hsc-button-sticky-rooms-exit.diadao-hsc-sticky-opened-visible',
    ) as HTMLButtonElement;
    if (bt) {
      bt.click();
    }
    router.push(roomsPageHref, undefined, {
      scroll: false,
    });
  };

  return (
    <Section
      className="room-detail"
      bgColor="brand.100"
      minH="100vh"
      pb={['24', '28', null, 0]}
    >
      <Box
        w="100%"
        position="fixed"
        top="0"
        left="0"
        className="rooms-nav"
        zIndex="20"
        mt={`var(${$kustomAdminBarHeightVarName}, 0)`}
      >
        <IconButton
          position="absolute"
          zIndex={3}
          shadow="sm"
          bgColor="brand.500"
          color="white"
          w={10}
          h={10}
          top="50%"
          transform="translateY(-50%)"
          right={4}
          aria-label={'close'}
          onClick={goBack}
          fontSize="18px"
          _hover={{
            bgColor: 'brand.600',
            color: 'white',
          }}
        >
          <IconSvg icon="cross" />
        </IconButton>
        {list.length > 0 && <ScrollPan list={list} route={route}></ScrollPan>}
      </Box>
      {children}
    </Section>
  );
};

export default RoomLayout;
