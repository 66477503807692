import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';

import {
  KustomPage,
  KustomPageComponent,
  RoomSpecsComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import { Box, chakra, Text } from '@chakra-ui/react';
import Link from 'next/link';
import { useContext, useEffect, useRef } from 'react';

type ScrollPanProps = {
  list: KustomPage[];
  route: string;
};

const ChakraLink = chakra(Link);

export function ScrollPan({ list, route }: Readonly<ScrollPanProps>) {
  const scrollEl = useRef<HTMLDivElement>(null);
  const scrollElContent = useRef<HTMLDivElement>(null);
  const scrollElWidth = useRef<number>(0);
  const scrollElContentWidth = useRef<number>(0);

  const cursor = useRef<{
    start: number;
    isDown: boolean;
    delta: number;
  }>({
    start: 0,
    isDown: false,
    delta: 0,
  });

  const scroll = useRef<{
    start: number;
    max: number;
  }>({
    start: 0,
    max: 0,
  });

  function handleDown(e: React.MouseEvent<HTMLDivElement>) {
    if (!scrollEl.current || !scrollElContent.current) return;
    cursor.current = {
      start: e.clientX,
      isDown: true,
      delta: 0,
    };
    scroll.current = {
      start: scrollEl.current.scrollLeft,
      max: scrollElContentWidth.current - scrollElWidth.current,
    };
    scrollElWidth.current = scrollEl.current.getBoundingClientRect().width;
    scrollElContentWidth.current =
      scrollElContent.current.getBoundingClientRect().width;
  }

  function handleUp() {
    cursor.current.isDown = false;
  }

  function handleMove(e: React.MouseEvent<HTMLDivElement>) {
    if (!cursor.current.isDown) return;
    cursor.current.delta = cursor.current.start - e.clientX;
    let x = scroll.current.start + cursor.current.delta;
    if (x < 0) x = 0;
    if (x > scroll.current.max) x = scroll.current.max;
    scrollEl.current!.scrollTo({
      left: x,
    });
  }

  function handleClick(e: React.MouseEvent<HTMLDivElement>) {
    if (Math.abs(cursor.current.delta) > 5) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  function replacePan(link: HTMLAnchorElement) {
    const linkRect = link.getBoundingClientRect();
    const scrollX = scrollEl.current?.scrollLeft;
    if (scrollX === undefined) return;
    const newScrollX =
      scrollX + linkRect.x - (window.innerWidth - linkRect.width) / 2;
    scrollEl.current!.scrollTo({
      left: newScrollX,
      behavior: 'smooth',
    });
  }

  return (
    <Box
      ref={scrollEl}
      overflowX="auto"
      overflowY="hidden"
      style={{
        scrollbarWidth: 'none',
      }}
      bg="brand.50"
      boxShadow="0 0 1rem rgba(0, 0, 0, 0.05)"
      className="ignoreSmoothScroll"
      onMouseDown={handleDown}
      onMouseUp={handleUp}
      onMouseLeave={handleUp}
      onMouseMove={handleMove}
      background="#ffffff"
      onClick={handleClick}
      _before={{
        content: '""',
        position: 'absolute',
        zIndex: '2',
        top: '0',
        bottom: '5px',
        right: '0',
        width: '5rem',
        background:
          'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .5) 60%)',
        pointerEvents: 'none',
      }}
    >
      <Box
        ref={scrollElContent}
        display="grid"
        gridAutoColumns="max-content"
        gridAutoFlow="column"
        justifyContent="center"
        width="fit-content"
        minW="100%"
        paddingInlineStart="6"
        paddingInlineEnd="20"
        gap="8"
        style={{
          overscrollBehaviorInline: 'contain',
        }}
      >
        {list?.map((roomPage) => (
          <PanLink
            key={roomPage._id}
            component={roomPage}
            route={route}
            onClick={(e) => replacePan(e.currentTarget)}
            replacePan={replacePan}
          />
        ))}
      </Box>
    </Box>
  );
}

interface PanLinkProps {
  component: KustomPage;
  route: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  replacePan: (link: HTMLAnchorElement) => void;
}

const PanLink: React.FC<PanLinkProps> = ({
  component,
  route,
  onClick,
  replacePan,
}) => {
  const { currentLang } = useContext(LanguageContext);

  const specsCmp = component?.components[0] as
    | KustomPageComponent<RoomSpecsComponentData>
    | undefined;
  const specs = specsCmp?.data;
  const href = component?.prettyUrl[currentLang || 'fr'];
  const isFocused = route === href || route === `${currentLang}/${href}`;

  const linkRef = useRef<HTMLAnchorElement>(null);

  const textCmp = component?.components[1] as
    | KustomPageComponent<any>
    | undefined;

  const subTitle = textCmp?.data?.atomicComponents?.[0];

  useEffect(() => {
    if (isFocused && linkRef.current) replacePan(linkRef.current);
  }, []);

  return (
    <ChakraLink
      ref={linkRef}
      href={href || '#'}
      display="flex"
      position="relative"
      userSelect="none"
      textDecoration="none !important"
      onClick={onClick}
      padding="1.25rem 0"
      color="gray.500"
      lineHeight="1.4"
      minHeight="5rem"
      flexDirection="column"
      alignContent="center"
      justifyContent="center"
      sx={{
        _before: {
          content: '""',
          position: 'absolute',
          bottom: '0',
          left: '0',
          right: '0',
          height: '3px',
          bgColor: 'brand.500',
          transition: 'transform 0.3s ease-in-out',
          transformOrigin: 'right',
          transform: isFocused ? 'scaleX(1)' : 'scaleX(0)',
        },
        _hover: {
          _before: {
            transformOrigin: 'left',
            transform: `scaleX(1)`,
            transition: 'transform 0.3s ease-in-out',
          },
        },
      }}
    >
      <TranslatedString
        fontWeight="700"
        // textOverflow="ellipsis"
        // maxW="13em"
        maxW="20em"
        whiteSpace="nowrap"
        overflow="hidden"
        as="span"
      >
        {specs?.title}
      </TranslatedString>
      {subTitle && (
        <RichText
          value={subTitle.text}
          fontSize="0.85em"
          textOverflow="ellipsis"
          maxW="20em"
          whiteSpace="nowrap"
          overflow="hidden"
        />
      )}
      {/* </Box> */}
    </ChakraLink>
  );
};
