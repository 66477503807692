import { KustomPage } from '@/lib/kustomcms-sdk/lib/types';
import React, { useContext, useMemo } from 'react';

import Head from 'next/head';
import KustomComponent from '@/lib/kustomcms-sdk/lib/components/KustomComponent';
import { LanguageContextKey } from '@/lib/kustomcms-sdk/lib/contexts/language';
import PagesContext from '@/lib/kustomcms-sdk/lib/contexts/pages';
import RoomLayout from '@/components/rooms/RoomLayout';
import { layoutArgs } from '@/next';
import { useRouter } from 'next/router';
import { Box } from '@chakra-ui/react';

const page404Id = '3h549n';

const DynamicSlugPage = (props: any) => {
  const router = useRouter();
  const pagesContext = useContext(PagesContext);

  // PROV only FR
  const { locale } = useRouter();
  const language = locale ? (locale as LanguageContextKey) : 'fr';
  // const language = 'fr'; // > MULTILANG
  const slug = ((router.query.slug || []) as string[])?.join('/');

  const content = useMemo(() => {
    const content = pagesContext.pages.find(
      (page) =>
        page.prettyUrl[language] === `${language}/${slug}` ||
        page.prettyUrl[language] === slug,
    );
    if (content) return content;
    const content404 = pagesContext.pages.find(
      (page) => page.pageCode === page404Id || page.objectCode === page404Id,
    );
    if (content404) return content404;
    return null;
  }, [slug, pagesContext.pages]);

  if (!content || slug === '404') {
    return <>404</>;
  }

  const hasPageHeader = content.components[0]?.type === 'textImage' || null;
  const pageHeader = hasPageHeader ? content.components[0] : null;

  const components = pageHeader
    ? content.components.slice(1)
    : content.components;

  return (
    <>
      <Head>
        <title>
          {/* {content.metaTitle?.[language.currentLang || 'fr'] || */}
          {content.metaTitle?.[language] || 'La Maison Nova'}
        </title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta
          name="description"
          // content={content.metaDescription?.[language.currentLang || 'fr']}
          content={content.metaDescription?.[language]}
        />
        {(content.category === 'rgpd' ||
          content.pageCode === page404Id ||
          content.objectCode === page404Id) && (
          <>
            <meta name="robots" content="noindex, nofollow" />
            <meta name="googlebot" content="noindex, nofollow" />
            <meta name="bingbot" content="noindex, nofollow" />
          </>
        )}
      </Head>
      {pageHeader && (
        <KustomComponent
          key={pageHeader.id}
          component={pageHeader}
          page={content}
        />
      )}
      <Box background="brand.100" position="relative" zIndex="2" flexGrow="1">
        {components.map((component, index) => (
          <KustomComponent
            key={component.id}
            component={component}
            page={content}
            index={index}
          />
        ))}
      </Box>
    </>
  );
};

DynamicSlugPage.getLayout = (
  route: string,
  kustomPage?: KustomPage,
  pages?: KustomPage[],
) => {
  if (kustomPage?.model === 'room') {
    const Layout = ({ page }: layoutArgs) => (
      <RoomLayout route={route}>{page}</RoomLayout>
    );
    return Layout;
  }
};

DynamicSlugPage.displayName = 'DynamicPage';

export default DynamicSlugPage;
